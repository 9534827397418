import React from 'react';
import { View, Text, StyleSheet, Dimensions, Linking } from 'react-native';
import { useMediaQuery } from 'react-responsive';

const height = Dimensions.get('window').height;
const width = Dimensions.get('window').width;
const goldenRatio = 1.618033987;

const goldenRatioCalculator = width / goldenRatio;

// styling
const styles = StyleSheet.create({
  container: {
    height: height,
    width: width,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  headline: {
    flex: 0.2,
    borderColor: 'black'
  },
  body: {
    flex: 0.8,
    borderColor: 'red'
  }
})

const responsiveFont = (percent) => {
  const heightPercent = percent * (height / 100);
  return Math.round(heightPercent);
};

function App () { 
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 }) 
    return (
      <View style={styles.container}>
        <View style={ isDesktopOrLaptop ? { width: width - goldenRatioCalculator, height: '100%', paddingVertical: '6%', paddingLeft: '6%'}
                      : {width: '100%', height: '100%', paddingVertical: '6%', paddingLeft: '6%', paddingRight: '6%'} }>
          <View style={styles.headline}>
              <Text style={{ fontWeight: '700', fontSize: responsiveFont(8) }}>Hello.</Text>
              <Text style={{ paddingTop: '4.5%', fontWeight: '400', fontSize: responsiveFont(4) }}>It's me! Yusuf.</Text>
          </View>
          <View style={styles.body}>
            <Text style={{ fontWeight: '300', fontSize: responsiveFont(3), paddingTop: '6%', textAlign: 'justify' }}>
                My full name is Yusuf Salman Noerinaldy Siregar, most of my friends call me Yusuf.
            </Text>
            <Text style={{ fontWeight: '300', fontSize: responsiveFont(3), paddingTop: '6%', textAlign: 'justify' }}>
                I am a product and tech professional with a passion for product strategy, full-stack development, and data analysis.
            </Text>
            <Text style={{ fontWeight: '300', fontSize: responsiveFont(3), paddingTop: '6%', textAlign: 'justify' }}>
                My code endeavour is mostly open source and available on <Text style={{ fontWeight: '400', fontSize: responsiveFont(3), fontStyle: 'italic' }} onPress={() => Linking.openURL('https://github.com/yusufsiregar44')}>GitHub</Text>. 
                Add me on <Text style={{ fontWeight: '400', fontSize: responsiveFont(3), fontStyle: 'italic' }} onPress={() => Linking.openURL('https://linkedin.com/in/yusuf-siregar')}>LinkedIn</Text> for a more meaningful interaction.
            </Text>
            <Text style={{ fontWeight: '300', fontSize: responsiveFont(3), paddingTop: '6%', textAlign: 'justify' }}>
                Outside of my professional life, I enjoy video games, collecting mechanical timepieces, and exploring uncharted territories.
            </Text>
          </View>
        </View>
      </View>
    );
  }

export default App;
